import React, { useState, useEffect } from "react";
import { Modal, Button } from "react-bootstrap";
import Api from "../API/profile";
import { validateForm } from "../config/commonFun";
import { Spinner } from "reactstrap";
import { ToastContainer, toast } from "react-toastify";
import {
  validZipCodeRegex,
  validPhoneRegex,
  validEmailRegex,
} from "../config/appRegex";
//component import
// import * as inputFun from '../common-components/FormInputFunctions';

export default function EditLocationProfileModel(props) {
  const [loader, setLoader] = useState(false);
  const [address, setAddress] = useState({
    State: {
      Id: 0,
      Name: "",
      Code: "",
    },
    Country: {
      Id: 0,
      Name: "",
      Code: "",
      ISOCode: "",
    },
    Id: 0,
    Name: "",
    Address1: "",
    Address2: "",
    City: "",
    ZipCode: "",
    Email: "",
    Phone: "",
    Fax: "",
    ContactType: 0,
    NPINumber: "",
  });

  const [errors, setErrors] = useState({
    State: "",
    Country: "",
    Name: "",
    Address1: "",
    City: "",
    ZipCode: "",
    Email: "",
    Phone: "",
    Fax: "",
    NPINumber: "",
  });

  const [state, setState] = useState([
    {
      Id: 0,
      Name: "Select State",
      Code: "",
    },
  ]);

  const getState = () => {
    Api.getState("US")
      .then((res) => {
        res.data.Result.unshift({
          Id: 0,
          Name: "Select State",
          Code: "",
        });
        setState(res.data.Result);
      })
      .catch((error) => {});
  };

  useEffect(() => {
    setAddress((userData) => ({
      ...userData,
      State: state[0].Code,
    }));
  }, [state.length]);

  useEffect(() => {
    if (props.address != null) {
      setAddress({ ...props.address });
    } else {
      setAddress({
        State: {
          Id: 0,
          Name: "",
          Code: "",
        },
        Country: {
          Id: 0,
          Name: "",
          Code: "",
          ISOCode: "",
        },
        Id: 0,
        Name: "",
        Address1: "",
        Address2: "",
        City: "",
        ZipCode: "",
        Email: "",
        Phone: "",
        Fax: "",
        ContactType: 0,
        NPINumber: "",
      });
    }
    getState();
    setErrors({
      State: "",
      Country: "",
      Name: "",
      Address1: "",
      City: "",
      ZipCode: "",
      Email: "",
      Phone: "",
      Fax: "",
      NPINumber: "",
    });
  }, [props.address]);

  const handelChange = (event) => {
    event.persist();
    let { name, value } = event.target;
    // value = value.trim();
    let errorValue = "";
    if (name != "State") {
      setAddress((userData) => ({
        ...userData,
        [name]: value,
      }));
    }
    switch (name) {
      case "Name":
        errorValue =
          value.trim().length == 0 ? "Location Name is required." : "";
        break;
      case "Address1":
        errorValue = value.trim().length == 0 ? "Address1 is required." : "";
        break;
      case "Email":
        if (value.trim().length == 0) {
          errorValue = "Email is required.";
        } else {
          errorValue = validEmailRegex.test(value) ? "" : "Enter valid email.";
        }
        break;
      case "Phone":
        errorValue = validPhoneRegex.test(value)
          ? ""
          : "Enter valid Phone Number.";
        break;
      case "ZipCode":
        if (value.trim().length == 0) {
          errorValue = "Zip Code is required.";
        } else {
          errorValue = validZipCodeRegex.test(value)
            ? ""
            : "Enter valid Zip Code.";
        }
        break;
      case "City":
        errorValue = value.trim().length == 0 ? "City is required." : "";
        break;
      case "State":
        let objState = address.State;
        if (objState != null) {
          objState.Code = value;
          address.State = objState;
        } else {
          let obj = {
            Id: 0,
            Name: "",
            Code: "",
          };
          obj.Code = value;
          address.State = obj;
        }
        errorValue = value.trim().length == 0 ? "State is required." : "";
        break;
      case "NPINumber":
        errorValue = value.trim().length == 0 ? "NPI Number is required." : "";
        break;

      default:
        break;
    }
    setErrors((errors) => ({
      ...errors,
      [name]: errorValue,
    }));
  };

  const handelSubmit = (event) => {
    let flagError = false;
    event.persist();
    event.preventDefault();
    if (address.Name.length == 0) {
      setErrors((errors) => ({
        ...errors,
        Name: "Location Name is required.",
      }));
      flagError = true;
    }
    if (address.Address1.length == 0) {
      setErrors((errors) => ({
        ...errors,
        Address1: "Address1 is required.",
      }));
      flagError = true;
    }
    if (address.City.length == 0) {
      setErrors((errors) => ({
        ...errors,
        City: "City is required.",
      }));
      flagError = true;
    }
    if (
      address.State == null ||
      address.State.Code == null ||
      address.State.Code == undefined ||
      address.State.Code == 0
    ) {
      setErrors((errors) => ({
        ...errors,
        State: "State is required.",
      }));
      flagError = true;
    }
    if (address.ZipCode.length == 0) {
      setErrors((errors) => ({
        ...errors,
        ZipCode: "ZipCode is required.",
      }));
      flagError = true;
    }
    if (address.Email.length == 0) {
      setErrors((errors) => ({
        ...errors,
        Email: "Email is required.",
      }));
      flagError = true;
    }
    // if (address.NPINumber.length == 0) {
    //   setErrors((errors) => ({
    //     ...errors,
    //     NPINumber: "NPINumber is required.",
    //   }));
    //   flagError = true;
    // }
    if (validateForm(errors) && !flagError) {
      setLoader(true);
      Api.postAddress(address)
        .then((res) => {
          toast.success("Location updated successfully!");
          props.reload();
          setTimeout(() => {
            setLoader(false);
            props.onHide();
          }, 3000);
        })
        .catch((error) => {
          setLoader(false);
          if (error.response.status == 400 && error.response.data.Result) {
            toast.error(error.response.data.Message);
          } else {
            toast.error("Something went wrong");
          }
        });
    }
  };

  return (
    <div className="maplayout-page">
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="app-model location-profile-model"
        id="drpro-select"
      >
        <Modal.Header closeButton>
          <Modal.Title
            id="contained-modal-title-vcenter "
            className="w-100  pb-0 blue-title-h6"
          >
            {props.address != null ? "Edit Location" : "Add Location"}
            {/* Edit Location */}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="app-model-body">
          <form onSubmit={handelSubmit}>
            <div className="row ">
              <div className=" col-sm-6 ">
                <div className="form-group app-ctrl has-value">
                  <label className="required">Location </label>
                  <input
                    type="text"
                    className="form-control"
                    value={address.Name}
                    name="Name"
                    onChange={handelChange}
                  />
                  {errors.Name.length > 0 && (
                    <div className="err-message">{errors.Name}</div>
                  )}
                </div>
              </div>
              <div className=" col-sm-6 ">
                <div className="form-group app-ctrl has-value">
                  <label className="required">Street Address Line 1</label>
                  <input
                    type="text"
                    className="form-control"
                    value={address.Address1}
                    name="Address1"
                    onChange={handelChange}
                  />
                  {errors.Address1.length > 0 && (
                    <div className="err-message">{errors.Address1}</div>
                  )}
                </div>
              </div>
              <div className=" col-sm-6 ">
                <div className="form-group app-ctrl has-value">
                  <label className="required">City</label>
                  <input
                    type="text"
                    className="form-control"
                    value={address.City}
                    name="City"
                    onChange={handelChange}
                  />
                  {errors.City.length > 0 && (
                    <div className="err-message">{errors.City}</div>
                  )}
                </div>
              </div>
              <div className=" col-sm-6 ">
                <div className="form-group app-ctrl has-value">
                  <label className="required">State</label>
                  <select
                    className="form-control"
                    name="State"
                    onChange={handelChange}
                    value={address.State != null ? address.State.Code : ""}
                  >
                    {state.length > 0 &&
                      state.map((s) => (
                        <option value={s.Code}>{s.Name}</option>
                      ))}
                  </select>
                  {errors.State.length > 0 && (
                    <div className="err-message">{errors.State}</div>
                  )}
                </div>
              </div>
              <div className=" col-sm-6 ">
                <div className="form-group app-ctrl has-value">
                  <label className="required">Zip Code</label>
                  <input
                    type="text"
                    className="form-control"
                    value={address.ZipCode}
                    name="ZipCode"
                    onChange={handelChange}
                  />
                </div>
                {errors.ZipCode.length > 0 && (
                  <div className="err-message">{errors.ZipCode}</div>
                )}
              </div>
              <div className=" col-sm-6 ">
                <div className="form-group app-ctrl has-value">
                  <label className="required">Email</label>
                  <input
                    type="text"
                    className="form-control"
                    value={address.Email}
                    name="Email"
                    onChange={handelChange}
                  />
                  {errors.Email.length > 0 && (
                    <div className="err-message">{errors.Email}</div>
                  )}
                </div>
              </div>
              <div className=" col-sm-6 ">
                <div class="form-group app-ctrl has-value">
                  <label className="">Phone Number</label>
                  <input
                    type="text"
                    className="form-control"
                    value={address.Phone}
                    name="Phone"
                    onChange={handelChange}
                  />
                  {errors.Phone.length > 0 && (
                    <div className="err-message">{errors.Phone}</div>
                  )}
                </div>
              </div>
              <div className=" col-sm-6 ">
                <div className="form-group app-ctrl has-value">
                  <label className="">Fax Number</label>
                  <input
                    type="text"
                    className="form-control"
                    value={address.Fax}
                    name="Fax"
                    onChange={handelChange}
                  />
                  {errors.Fax.length > 0 && (
                    <div className="err-message">{errors.Fax}</div>
                  )}
                </div>
              </div>
              <div className=" col-sm-6 ">
                <div className="form-group app-ctrl has-value">
                  <label className="">NPI Number</label>
                  <input
                    type="text"
                    className="form-control"
                    name="NPINumber"
                    value={address.NPINumber}
                    onChange={handelChange}
                  />
                  {errors.NPINumber.length > 0 && (
                    <div className="err-message">{errors.NPINumber}</div>
                  )}
                </div>
              </div>
            </div>
            <div className="btn-box d-flex justify-content-center">
              <button
                type="button"
                className="btn app-btn lightgray-btn large-btn mr-2"
                onClick={props.onHide}
              >
                cancel
              </button>
              <button
                type="submit"
                className={`btn app-btn large-btn btn-theme-color ${
                  loader ? "btn-loading-state" : ""
                }`}
              >
                <div className="button-inner">
                  {loader && <Spinner style={{ verticalAlign: "middle" }} />}
                  {/* Update */}
                  <span className="btn-text">
                    {props.address != null ? "Update" : "Save"}
                  </span>
                </div>
              </button>
            </div>
          </form>
        </Modal.Body>
      </Modal>
      {/* <ToastContainer hideProgressBar /> */}
    </div>
  );
}
