import React, { useEffect, useState } from "react";
import { Modal, Button } from "react-bootstrap";

function Title_11(props) {
  const [selectedWidget, setSelectedWidget] = useState({
    title: "",
    tooltip: "",
  });

  useEffect(() => {
    if (props.selectedWidget != null) {
      setSelectedWidget(props.selectedWidget);
    }
  }, [props.selectedWidget]);

  return (
    <div className="maplayout-page">
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="app-model sampleRequest-model"
        id="drpro-select"
      >
        <Modal.Header closeButton>
          <Modal.Title
            id="contained-modal-title-vcenter "
            className="w-100  pb-0 blue-title-h6"
          >
            {selectedWidget.title}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="app-model-body">
          <p> {selectedWidget.tooltip}</p>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default Title_11;
