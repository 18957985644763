import React from "react";

export default function AppSectionLoader() {
  return (
    <div
      className="loader-wrapper align-items-center justify-content-center section-loader"
      id="appLoadingMain"
    >
      <div id="loading-bar-spinner" className="spinner">
        <div className="spinner-icon"></div>
      </div>
    </div>
  );
}
