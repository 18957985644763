import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import Icon1 from "../assets/images/user-green.png";
import Icon2 from "../assets/images/icon-1.png";
import Icon3 from "../assets/images/phone-green.png";
import Icon4 from "../assets/images/icon-2.png";
import Icon5 from "../assets/images/eye.png";
import Icon6 from "../assets/images/practice.png";
import Icon7 from "../assets/images/practice-address.png";
import Icon8 from "../assets/images/no-oparetion.png";
import Icon9 from "../assets/images/right-arrow.svg";
import logo from "../assets/images/logo-white.svg";
import street from "../assets/images/Street.svg";
import city from "../assets/images/city.svg";
import state from "../assets/images/State.svg";
import country from "../assets/images/Country.svg";
import ZipCode from "../assets/images/zip-code.svg";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Spinner,
} from "reactstrap";
import Home from "../Home";
import { Multiselect } from "multiselect-react-dropdown";
import {
  validEmailRegex,
  validCharRegex,
  validPhoneRegex,
  validPasswordRegex,
  validZipCodeRegex,
} from "../config/appRegex";
import { validateForm } from "../config/commonFun";
import { Tooltip } from "reactstrap";
import SpecialityIcon from "../assets/images/no-oparetion.png";
import {
  Logo,
  ProductName,
  LogoWithLabel,
  ThemeColor,
  Title,
} from "../config/appConstatnts";

import Api from "../API/signup";
import { faRoad } from "@fortawesome/free-solid-svg-icons";

// export default function SignInPage1(){
const SignInPage1 = (props) => {
  let history = useHistory();
  const redirectToSignin = () => {
    history.push("/signin");
  };

  const { buttonLabel, className } = props;

  const [modal, setModal] = useState(true);

  const toggle = () => setModal(modal);

  const [stateList, setStateList] = useState([]);

  const [tooltipOpen, setTooltipOpen] = useState(false);
  const toggleTooltip = () => setTooltipOpen(!tooltipOpen);
  const [passwordType, setPasswordType] = useState("password");
  const [cpasswordType, setCPasswordType] = useState("password");

  const [eyeTog2, seteyeTog2] = useState("false");
  const changePasswordType = () => {
    passwordType == "password"
      ? setPasswordType("text")
      : setPasswordType("password");
    seteyeTog2(!eyeTog2);
  };

  const [eyeTog3, seteyeTog3] = useState("false");
  const changeConPasswordType = () => {
    cpasswordType == "password"
      ? setCPasswordType("text")
      : setCPasswordType("password");
    seteyeTog3(!eyeTog3);
  };

  // Set step model
  const [step, setStep] = useState(1);

  // Set response message model
  const [resMessage, setResMessage] = useState("");

  // Set Loader model
  const [loader, setLoader] = useState(false);

  // Set Signup model
  const [userModel, setUserModel] = useState({
    FirstName: "",
    LastName: "",
    Email: "",
    Phone: "",
    Password: "",
    ConPassword: "",
  });

  // Set address model
  const [addressModel, setAddressModel] = useState({
    StreetAddress: "",
    City: "",
    State: "",
    ZipCode: "",
  });

  // Set Parctice model
  const [parcticeModel, setParcticeModel] = useState({
    PracticeName: "",
    Speciality: [1],
  });

  // Set user error model
  const [userModelErrors, setUserModelErrors] = useState({
    FirstName: "",
    LastName: "",
    Email: "",
    Phone: "",
    Password: "",
    ConPassword: "",
  });

  // Set address error model
  const [addressModelErrors, setAddressModelErrors] = useState({
    StreetAddress: "",
    City: "",
    State: "",
    ZipCode: "",
  });

  // Set Parctice model
  const [parcticeErrorModel, setParcticeErrorModel] = useState({
    PracticeName: "",
    Speciality: "",
  });

  const [selectedSpecialty, setSelectedSpecialty] = useState({
    Id: 0,
    Name: "",
  });

  const [specialty, setSpecialty] = useState([
    {
      Id: 0,
      Description: "",
    },
  ]);

  const [option, setOption] = useState({
    id: 0,
    name: "",
  });

  useEffect(() => {
    getSpecialities();
    getState();
  }, [step == 2]);

  useEffect(() => {
    let ops = [];
    for (let i = 0; i < specialty.length; i++) {
      let obj = { id: 0, name: "" };
      obj.id = specialty[i].Id;
      obj.name = specialty[i].Description;
      ops.push(obj);
    }
    setOption(ops);
  }, [specialty.length]);

  useEffect(() => {
    if (state.length > 0) {
      setAddressModel((addressModel) => ({
        ...addressModel,
        ["State"]: state[0].Code,
      }));
    }
  }, [state.length]);

  // Retrieve state for country
  const getState = () => {
    Api.getState("US")
      .then((res) => {
        let list = [{ value: "", label: "Select State" }];
        if (res.data && res.data.Result) {
          for (let index = 0; index < res.data.Result.length; index++) {
            const element = res.data.Result[index];
            list.push({ value: element.Code, label: element.Name });
          }
        }
        setStateList((stateList) => [...list]);
      })
      .catch((error) => {
        setStateList((stateList) => [{ value: "", label: "Select State" }]);
      });
  };

  // Form element change event handler
  const handelUserModelChange = (event) => {
    event.persist();
    let { name, value } = event.target;

    if (name != "Password" && name != "ConPassword") {
      value = value.trim();
    }
    let errorValue = "";

    // Set updated value to userModel
    setUserModel((userModel) => ({
      ...userModel,
      [name]: value,
    }));

    // Input validation
    switch (name) {
      case "FirstName":
        if (value.length == 0) {
          errorValue = "First Name is required.";
        } else {
          errorValue = validCharRegex.test(value)
            ? ""
            : "Enter only characters.";
        }
        break;
      case "LastName":
        if (value.length == 0) {
          errorValue = "Last Name is required.";
        } else {
          errorValue = validCharRegex.test(value)
            ? ""
            : "Enter only characters.";
        }
        break;
      case "Email":
        if (value.length == 0) {
          errorValue = "Email is required.";
        } else {
          errorValue = validEmailRegex.test(value) ? "" : "Enter valid email.";
        }
        break;
      case "Phone":
        if (value.length == 0) {
          errorValue = "Phone number is required.";
        } else {
          errorValue = validPhoneRegex.test(value)
            ? ""
            : "Enter valid phone number.";
        }
        break;
      case "Password":
        if (value.length == 0) {
          errorValue = "Password is required.";
        } else {
          errorValue = validPasswordRegex.test(value)
            ? ""
            : "Enter valid Password.";
        }
        break;
      case "ConPassword":
        if (value.length == 0) {
          errorValue = "Password is required.";
        } else {
          errorValue =
            userModel.Password == value ? "" : "Password don't match.";
        }
        break;
      default:
        break;
    }
    setUserModelErrors((userModelErrors) => ({
      ...userModelErrors,
      [name]: errorValue,
    }));
  };

  // Address form element change event handler
  const handelAddressModelChange = (event) => {
    event.persist();
    let { name, value } = event.target;

    // value = value.trim();

    let errorValue = "";

    // Set updated value to userModel
    setAddressModel((addressModel) => ({
      ...addressModel,
      [name]: value,
    }));

    // Input validation
    switch (name) {
      case "StreetAddress":
        if (value.trim().length == 0) {
          errorValue = "Street Address is required.";
        }
        break;
      case "City":
        if (value.trim().length == 0) {
          errorValue = "City is required.";
        }
        break;
      case "State":
        if (value.trim().length == 0) {
          errorValue = "State is required.";
        }
        break;
      case "ZipCode":
        if (value.trim().length == 0) {
          errorValue = "Zip Code is required.";
        } else {
          errorValue = validZipCodeRegex.test(value)
            ? ""
            : "Enter valid Zip Code.";
        }
      default:
        break;
    }

    setAddressModelErrors((addressModelErrors) => ({
      ...addressModelErrors,
      [name]: errorValue,
    }));
  };

  // Practic for element change event handler
  const handelParcticModelChange = (event) => {
    event.persist();
    let { name, value } = event.target;

    // value = value.trim();

    let errorValue = "";

    // Set updated value to userModel
    setParcticeModel((parcticeModel) => ({
      ...parcticeModel,
      [name]: value,
    }));

    // Input validation
    switch (name) {
      case "PracticeName":
        if (value.trim().length == 0) {
          errorValue = "Practice Name is required.";
        }
        break;
      default:
        break;
    }
    setParcticeErrorModel((parcticeErrorModel) => ({
      ...parcticeErrorModel,
      [name]: errorValue,
    }));
  };

  const onSecondPage = (event) => {
    event.preventDefault();
    let flagError = false;

    //Usermodel validation
    if (userModel.FirstName.length == 0) {
      setUserModelErrors((userModelErrors) => ({
        ...userModelErrors,
        ["FirstName"]: "FirstName is required.",
      }));
      flagError = true;
    }
    if (userModel.LastName.length == 0) {
      setUserModelErrors((userModelErrors) => ({
        ...userModelErrors,
        ["LastName"]: "Last Name is required.",
      }));
      flagError = true;
    }
    if (userModel.Email.length == 0) {
      setUserModelErrors((userModelErrors) => ({
        ...userModelErrors,
        ["Email"]: "Email is required.",
      }));
      flagError = true;
    }
    if (userModel.Phone.length == 0) {
      setUserModelErrors((userModelErrors) => ({
        ...userModelErrors,
        ["Phone"]: "Phone is required.",
      }));
      flagError = true;
    }
    if (userModel.Password.length == 0) {
      setUserModelErrors((userModelErrors) => ({
        ...userModelErrors,
        ["Password"]: "Password is required.",
      }));
      flagError = true;
    }
    if (userModel.ConPassword.length == 0) {
      setUserModelErrors((userModelErrors) => ({
        ...userModelErrors,
        ["ConPassword"]: "Password is required.",
      }));
      flagError = true;
    }

    // Signup User
    if (validateForm(userModelErrors) && !flagError) {
      setLoader(true);

      // Check for email
      Api.getCheckEmail(userModel.Email)
        .then((res) => {
          setLoader(false);
          // If email not exist go to second step
          res.data.Result != -1
            ? setUserModelErrors((userModelErrors) => ({
                ...userModelErrors,
                ["Email"]: res.data.Message,
              }))
            : setStep(2);
        })
        .catch((error) => {
          setLoader(false);
          setResMessage(JSON.parse(error.request.response).Message);
        });
    }
  };

  const goToStepOne = () => {
    setStep(1);
  };

  // Set second step for signup
  const goToStepTwo = () => {
    setStep(2);
  };

  // Set third step for signup
  const onThirdPage = (event) => {
    event.preventDefault();
    let flagError = false;
    if (addressModel.StreetAddress.length == 0) {
      setAddressModelErrors((addressModelErrors) => ({
        ...addressModelErrors,
        ["StreetAddress"]: "Street Address is required.",
      }));
      flagError = true;
    }
    if (addressModel.City.length == 0) {
      setAddressModelErrors((addressModelErrors) => ({
        ...addressModelErrors,
        ["City"]: "City is required.",
      }));
      flagError = true;
    }
    if (
      addressModel.State == null ||
      addressModel.State == undefined ||
      addressModel.State.length == 0
    ) {
      setAddressModelErrors((addressModelErrors) => ({
        ...addressModelErrors,
        ["State"]: "State is required.",
      }));
      flagError = true;
    }
    if (addressModel.ZipCode.length == 0) {
      setAddressModelErrors((addressModelErrors) => ({
        ...addressModelErrors,
        ["ZipCode"]: "Zip Code is required.",
      }));
      flagError = true;
    } else if (!validZipCodeRegex.test(addressModel.ZipCode)) {
      setAddressModelErrors((addressModelErrors) => ({
        ...addressModelErrors,
        ["ZipCode"]: "Enter valid Zip Code.",
      }));
      flagError = true;
    }

    if (validateForm(addressModelErrors) && !flagError) {
      setStep(3);
    }
  };

  // User Signup
  const signUp = (event) => {
    event.preventDefault();

    let flagError = false;
    if (parcticeModel.PracticeName.length == 0) {
      setParcticeModel((parcticeModel) => ({
        ...parcticeModel,
        ["PracticeName"]: "Street Address is required.",
      }));
      flagError = true;
    }

    if (!flagError) {
      setLoader(true);
      let userInfo = {
        ...userModel,
        Address: {
          ...addressModel,
        },
        ...parcticeModel,
      };
      Api.signUp(userInfo)
        .then((res) => {
          setLoader(false);
          if (res.data.IsSuccess) {
            setResMessage(
              "Please check your email for a confirmation email to login to your account."
            );
            setTimeout(() => redirectToSignin(), 10000);
          }
          redirectToSignin();
        })
        .catch((error) => {
          setLoader(false);
          setResMessage(JSON.parse(error.request.response).Message);
        });
    }
  };

  const onSpeSelect = (obj, newObj) => {
    setParcticeErrorModel((errors) => ({
      ...errors,
      ["Speciality"]: "",
    }));
    setParcticeModel((userModel) => ({
      ...userModel,
      ["Speciality"]: obj.map((a) => a.id),
    }));
  };
  const onSpeRemove = (obj, reobj) => {
    if (obj.length == 0) {
      setParcticeErrorModel((errors) => ({
        ...errors,
        ["Speciality"]: "Specialty is required",
      }));
    }
    parcticeModel((userModel) => ({
      ...userModel,
      ["Speciality"]: obj.map((a) => a.id),
    }));
  };

  const getSpecialities = () => {
    Api.getSpecialities()
      .then((res) => {
        setSpecialty(res.data.Result);
      })
      .catch((error) => {});
  };

  return (
    <section className="common-section vh-0 login-comman-sec">
      <Modal
        isOpen={modal}
        toggle={toggle}
        className="modal-dialog-centered  sign-up-modal"
      >
        <ModalBody className="main-block signin-block signup1">
          <div className="login sub-block">
            <div className="row justify-content-center">
              <div className="col-5 bg-green bg-green-1 d-flex">
                <div className="justify-content-center align-self-center text-center">
                  <img src={LogoWithLabel} className="img-fluid logo-white" />
                  <h1>Welcome Back!</h1>
                  <p>
                    Increase profits with our proven dental marketing strategy
                    that focuses on patient communications.
                  </p>

                  <div className="btn-block signup-white-btn">
                    <button
                      type="button"
                      className="btn btn-outline-primary blue-btn-bg "
                      onClick={redirectToSignin}
                    >
                      sign in <i className="fa fa-arrow-right"></i>
                    </button>
                  </div>
                </div>
              </div>
              <div className="col-lg-7 pl-lg-0">
                <div className="d-block">
                  {step == 1 ? (
                    <div className="inner-block text-center sign-up">
                      <div className="block-1">
                        <h1>
                          Sign up to{" "}
                          <span>
                            <b>{ProductName}</b>
                          </span>
                        </h1>
                        <p className="mb-2">Enter your personal information </p>
                      </div>
                      <form>
                        <div className="content">
                          <div className="form-group">
                            <div className="position-relative">
                              <input
                                type="text"
                                className="form-control light-border"
                                id="firstName"
                                name="FirstName"
                                maxLength="20"
                                placeholder="First Name*"
                                value={userModel.FirstName}
                                onChange={handelUserModelChange}
                              />
                              <span className="input-icon1">
                                <i className="fa fa-user"></i>
                              </span>
                            </div>
                            <div className="err-message">
                              {userModelErrors.FirstName}
                            </div>
                          </div>
                          <div className="form-group">
                            <div className="position-relative">
                              <input
                                type="text"
                                className="form-control light-border"
                                id="lastName"
                                name="LastName"
                                maxLength="20"
                                placeholder="Last Name*"
                                value={userModel.LastName}
                                onChange={handelUserModelChange}
                              />
                              <span className="input-icon1">
                                <i className="fa fa-user"></i>
                              </span>
                            </div>
                            <div className="err-message">
                              {userModelErrors.LastName}
                            </div>
                          </div>
                          <div className="form-group">
                            <input
                              type="text"
                              className="form-control light-border"
                              id="email"
                              name="Email"
                              maxLength="100"
                              placeholder="Email Address*"
                              value={userModel.Email}
                              onChange={handelUserModelChange}
                            />
                            <span className="input-icon1">
                              <i className="fa fa-envelope"></i>
                            </span>
                          </div>
                          <div className="err-message">
                            {userModelErrors.Email}
                          </div>
                          <div className="form-group">
                            <input
                              type="text"
                              className="form-control light-border"
                              id="phoneNumber"
                              placeholder="Phone Number*"
                              name="Phone"
                              value={userModel.Phone}
                              onChange={handelUserModelChange}
                            />
                            <span className="input-icon1">
                              <i class="fa fa-phone"></i>
                            </span>
                          </div>
                          <div className="err-message">
                            {userModelErrors.Phone}
                          </div>
                          <div className="form-group" id="TooltipExample">
                            <Tooltip
                              placement="bottom"
                              isOpen={tooltipOpen}
                              target="TooltipExample"
                              toggle={toggleTooltip}
                            >
                              Password must be a minimum of 8 characters long
                              and contain an upper and lower case letter, a
                              number and a symbol.
                            </Tooltip>
                            <span className="input-icon1">
                              <i class="fa fa-lock"></i>
                            </span>
                            <input
                              type={passwordType}
                              className="form-control light-border pass-input"
                              id="password"
                              maxLength="50"
                              placeholder="Password*"
                              name="Password"
                              value={userModel.Password}
                              onChange={handelUserModelChange}
                            />
                            {/* <span className="input-icon">
                            <img src={Icon5} alt="views" className="img-fluid" />
                        </span> */}
                            <span className="input-icon">
                              <i
                                className={`passTogEye icon-eye ${
                                  !eyeTog2 ? "passVisible" : ""
                                }`}
                                onClick={changePasswordType}
                              ></i>
                            </span>
                          </div>
                          <div className="err-message">
                            {userModelErrors.Password}
                          </div>
                          <div className="form-group">
                            <span className="input-icon1">
                              <i class="fa fa-lock"></i>
                            </span>
                            <input
                              type={cpasswordType}
                              className="form-control light-border pass-input"
                              id="conPassword"
                              maxLength="50"
                              placeholder="Confirm Password*"
                              name="ConPassword"
                              value={userModel.ConPassword}
                              onChange={handelUserModelChange}
                            />
                            {/* <span className="input-icon">
                        <img src={Icon5} alt="views" className="img-fluid" />

                        </span> */}
                            <span className="input-icon">
                              <i
                                className={`passTogEye icon-eye ${
                                  !eyeTog3 ? "passVisible" : ""
                                }`}
                                onClick={changeConPasswordType}
                              ></i>
                            </span>
                          </div>
                          <div className="err-message">
                            {userModelErrors.ConPassword}
                          </div>
                        </div>
                        <div className="account-btn-block">
                          <div className="btn-block">
                            <button
                              type="button"
                              className={`btn btn-outline-primary blue-btn-bg ${
                                loader ? "btn-loading-state" : ""
                              }`}
                              onClick={onSecondPage}
                            >
                              <div className="button-inner">
                                {loader && (
                                  <Spinner
                                    style={{ verticalAlign: "middle" }}
                                  />
                                )}
                                <span className="btn-text">next</span>
                              </div>
                            </button>
                          </div>
                        </div>
                        <div className="forget-link  text-right link-purple signup-link">
                          <p>
                            Already have Account?
                            <a
                              href="#"
                              className="h5 f-600 pl-2"
                              onClick={redirectToSignin}
                            >
                              Sign in
                            </a>
                          </p>
                        </div>
                      </form>
                    </div>
                  ) : step == 2 ? (
                    <div className="inner-block text-center sign-up">
                      <div className="block-1">
                        <h1>
                          Sign up to{" "}
                          <span>
                            <b>{ProductName}</b>
                          </span>
                        </h1>
                        <p className="mb-2">Enter your Practice Address</p>
                      </div>
                      <form>
                        <div className="content">
                          <div className="form-group">
                            <input
                              type="text"
                              className="form-control light-border"
                              id="streetAddress"
                              placeholder="Street Address*"
                              name="StreetAddress"
                              value={addressModel.StreetAddress}
                              onChange={handelAddressModelChange}
                            />
                            <span className="input-icon1">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="22.822"
                                height="29.962"
                                viewBox="0 0 22.822 29.962"
                              >
                                <g id="Street" transform="translate(-61.001)">
                                  <path
                                    id="Path_273"
                                    data-name="Path 273"
                                    d="M307.765,216.01l1.756-1.756a.878.878,0,0,0,0-1.241l-1.756-1.756a.877.877,0,0,0-.621-.257H301v5.267h6.145A.877.877,0,0,0,307.765,216.01Z"
                                    transform="translate(-225.955 -198.653)"
                                    fill="#f87c56"
                                  />
                                  <path
                                    id="Path_274"
                                    data-name="Path 274"
                                    d="M307.765,96.01l1.756-1.756a.877.877,0,0,0,0-1.241l-1.756-1.756a.877.877,0,0,0-.621-.257H301v5.267h6.145A.877.877,0,0,0,307.765,96.01Z"
                                    transform="translate(-225.955 -85.675)"
                                    fill="#f87c56"
                                  />
                                  <path
                                    id="Path_275"
                                    data-name="Path 275"
                                    d="M182.756,28.206h-.878a.878.878,0,0,0,0,1.756H188.9a.878.878,0,0,0,0-1.756h-.878V20.248a.877.877,0,0,0-.878-.878h-.878V.878a.878.878,0,1,0-1.756,0V19.37h-.878a.877.877,0,0,0-.878.878Z"
                                    transform="translate(-112.977)"
                                    fill="#f87c56"
                                  />
                                  <path
                                    id="Path_276"
                                    data-name="Path 276"
                                    d="M63.013,156.01a.877.877,0,0,0,.621.257h6.144V151H63.634a.877.877,0,0,0-.621.257l-1.756,1.756a.878.878,0,0,0,0,1.241Z"
                                    transform="translate(0 -142.164)"
                                    fill="#f87c56"
                                  />
                                  <path
                                    id="Path_277"
                                    data-name="Path 277"
                                    d="M63.013,36.01a.877.877,0,0,0,.621.257h6.144V31H63.634a.877.877,0,0,0-.621.257l-1.756,1.756a.878.878,0,0,0,0,1.241Z"
                                    transform="translate(0 -29.186)"
                                    fill="#f87c56"
                                  />
                                </g>
                              </svg>
                            </span>
                          </div>
                          <div className="err-message">
                            {addressModelErrors.StreetAddress}
                          </div>
                          <div className="form-group">
                            <input
                              type="text"
                              className="form-control light-border"
                              id="city"
                              placeholder="City*"
                              name="City"
                              value={addressModel.City}
                              onChange={handelAddressModelChange}
                            />
                            <span className="input-icon1">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="27"
                                height="27"
                                viewBox="0 0 27 27"
                              >
                                <g id="city" transform="translate(0 1)">
                                  <path
                                    id="Path_272"
                                    data-name="Path 272"
                                    d="M24.991,25.418V16.979H15.9v8.439H14.32V15.4h5.336V6.374H17.877V2.84H15.864V0H14.282V2.84H12.27V6.374H10.491V25.418H8.909V9.909h-6.9V25.418H0V27H27V25.418ZM16.05,8.327h1.582V9.909H16.05Zm0,3.535h1.582v1.582H16.05ZM6.215,24.8H4.633V23.215H6.215Zm0-3.784H4.633V19.431H6.215Zm0-3.784H4.633V15.647H6.215Zm0-3.784H4.633V11.862H6.215Zm7.882,0H12.515V11.862H14.1Zm0-3.535H12.515V8.327H14.1Zm.185-5.117h1.582V6.374H14.282Zm5.177,20.626H17.877V19.069h1.582Zm3.557,0H21.434V19.069h1.582Z"
                                    transform="translate(0 -1)"
                                    fill="#f87c56"
                                  />
                                </g>
                              </svg>
                            </span>
                          </div>
                          <div className="err-message">
                            {addressModelErrors.City}
                          </div>
                          <div className="form-group select-form-group">
                            <span className="input-icon1">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="23.371"
                                height="25.032"
                                viewBox="0 0 23.371 25.032"
                              >
                                <g id="State" transform="translate(-16.996)">
                                  <path
                                    id="Path_283"
                                    data-name="Path 283"
                                    d="M39.48,370.887H33.044v-1.558h5.924l-.938-2.856h-4.47a29.468,29.468,0,0,0-3.693,3.98l-1.185,1.624L27.5,370.453a29.61,29.61,0,0,0-3.693-3.98h-4.47l-.938,2.856h5.924v1.558H17.883l-.887,2.7H40.367Z"
                                    transform="translate(0 -348.556)"
                                    fill="#f87c56"
                                  />
                                  <path
                                    id="Path_284"
                                    data-name="Path 284"
                                    d="M89.3,21.034c3.536-4.847,8.569-6.716,8.569-12.464a8.569,8.569,0,0,0-17.138,0C80.73,14.316,85.765,16.189,89.3,21.034Zm0-14.479a2.337,2.337,0,1,1-2.337,2.337A2.341,2.341,0,0,1,89.3,6.554Z"
                                    transform="translate(-60.618)"
                                    fill="#f87c56"
                                  />
                                </g>
                              </svg>
                            </span>

                            <select
                              className="form-control"
                              name="State"
                              value={addressModel.State}
                              onChange={handelAddressModelChange}
                            >
                              {stateList.map((item) => (
                                <option value={item.value} key={item.value}>
                                  {item.label}
                                </option>
                              ))}
                            </select>
                          </div>
                          <div className="err-message">
                            {addressModelErrors.State}
                          </div>
                          {/* <div className="form-group disabled">
                        <input type="text" className="form-control light-border" id="country" placeholder="Country*" defaultValue="United States" disabled />
                          <span className="input-icon1">
                              <img src={country} alt="views" className="img-fluid" />
                          </span>
                      </div> */}
                          <div className="form-group">
                            <input
                              type="text"
                              className="form-control light-border"
                              id="zipCode"
                              placeholder="Zip Code*"
                              name="ZipCode"
                              value={addressModel.ZipCode}
                              onChange={handelAddressModelChange}
                            />
                            <span className="input-icon1">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="27.338"
                                height="19.222"
                                viewBox="0 0 27.338 19.222"
                              >
                                <g
                                  id="zip-code"
                                  transform="translate(439.446 618.048)"
                                >
                                  <g
                                    id="Group_7745"
                                    data-name="Group 7745"
                                    transform="translate(-439.446 -618.048)"
                                  >
                                    <g id="Group_7744" data-name="Group 7744">
                                      <g id="Group_7746" data-name="Group 7746">
                                        <path
                                          id="Path_281"
                                          data-name="Path 281"
                                          d="M26.27,76H1.068A1.068,1.068,0,0,0,0,77.068V94.154a1.068,1.068,0,0,0,1.068,1.068h25.2a1.068,1.068,0,0,0,1.068-1.068V77.068A1.068,1.068,0,0,0,26.27,76Z"
                                          transform="translate(0 -76)"
                                          fill="#f87c56"
                                        />
                                        <circle
                                          id="Ellipse_10"
                                          data-name="Ellipse 10"
                                          cx="1.335"
                                          cy="1.335"
                                          r="1.335"
                                          transform="translate(14.203 10.278)"
                                          fill="#fff"
                                        />
                                        <circle
                                          id="Ellipse_11"
                                          data-name="Ellipse 11"
                                          cx="1.335"
                                          cy="1.335"
                                          r="1.335"
                                          transform="translate(17.94 10.278)"
                                          fill="#fff"
                                        />
                                        <circle
                                          id="Ellipse_12"
                                          data-name="Ellipse 12"
                                          cx="1.335"
                                          cy="1.335"
                                          r="1.335"
                                          transform="translate(10.465 10.278)"
                                          fill="#fff"
                                        />
                                        <circle
                                          id="Ellipse_13"
                                          data-name="Ellipse 13"
                                          cx="1.335"
                                          cy="1.335"
                                          r="1.335"
                                          transform="translate(6.728 10.278)"
                                          fill="#fff"
                                        />
                                        <path
                                          id="Path_282"
                                          data-name="Path 282"
                                          d="M132.068,175.636h11.213a1.068,1.068,0,1,0,0-2.136H132.068a1.068,1.068,0,1,0,0,2.136Z"
                                          transform="translate(-124.005 -168.294)"
                                          fill="#fff"
                                        />
                                      </g>
                                    </g>
                                  </g>
                                </g>
                              </svg>
                            </span>
                          </div>
                          <div className="err-message">
                            {addressModelErrors.ZipCode}
                          </div>
                        </div>
                        <div className="account-btn-block">
                          <div className="btn-block">
                            <button
                              type="button"
                              className={`btn btn-outline-primary blue-btn-bg ${
                                loader ? "btn-loading-state" : ""
                              }`}
                              onClick={onThirdPage}
                            >
                              <div className="button-inner">
                                {loader && (
                                  <Spinner
                                    style={{ verticalAlign: "middle" }}
                                  />
                                )}
                                <span className="btn-text">next</span>
                              </div>
                            </button>
                          </div>
                        </div>
                        {/* <div className="account-btn-block">
                      <div className="btn-block">
                        <button type="button" className="btn btn-outline-primary   blue-btn-bg ">Sign up</button>
                      </div>
                    </div> */}
                        {/* <div className="forget-link  text-right link-purple signup-link">
                      <p>Already have Account ?<a href="#" className="h5 f-600 pl-2">Sign in</a></p>
                    </div> */}
                        <div className="account-btn-block text-center pt-2">
                          <a
                            className="back-button mr-auto pl-2"
                            onClick={goToStepOne}
                          >
                            <i className="fa fa-chevron-left"></i>
                            Back
                          </a>
                        </div>
                      </form>
                    </div>
                  ) : (
                    <div className="inner-block text-center ">
                      <div className="block-1">
                        <h1>
                          Sign up to{" "}
                          <span>
                            <b>{ProductName}</b>
                          </span>
                        </h1>
                        <p className="mb-2">Enter your Practice information</p>
                      </div>
                      <div>{resMessage}</div>
                      <form>
                        <div className="content">
                          <div className="form-group">
                            <input
                              type="text"
                              className="form-control light-border"
                              id="practiceName"
                              placeholder="Practice Name*"
                              name="PracticeName"
                              value={parcticeModel.PracticeName}
                              onChange={handelParcticModelChange}
                            />
                            <span className="input-icon1">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="32"
                                height="28"
                                viewBox="0 0 32 28"
                              >
                                <g>
                                  <g>
                                    <g>
                                      <path
                                        fill="#f87c56"
                                        d="M12.766 20.572h5.866v6.535h-5.866z"
                                      />
                                    </g>
                                    <g>
                                      <path
                                        fill="#f87c56"
                                        d="M2.2 22.428h2.07v1.84H2.2zm0-4.122h2.07v1.84H2.2zM0 27.107h6.47v-11.64H0z"
                                      />
                                    </g>
                                    <g>
                                      <path
                                        fill="#f87c56"
                                        d="M19.26 12.328h-2.068v-1.84h2.069zm0 4.122h-2.068v-1.84h2.069zm-5.053-4.122h-2.07v-1.84h2.07zm0 4.122h-2.07v-1.84h2.07zm6.265-10.606H18.61v1.861h-5.822V5.844h-1.861V3.853H8.31v23.254h2.617v-8.375h9.545v8.375h2.617V3.853h-2.617z"
                                      />
                                    </g>
                                    <g>
                                      <path
                                        fill="#f87c56"
                                        d="M29.198 20.146h-2.069v-1.84h2.069zm0 4.122h-2.069v-1.84h2.069zm-4.27-8.8v11.64h6.47v-11.64z"
                                      />
                                    </g>
                                    <g>
                                      <path
                                        fill="#f87c56"
                                        d="M14.628 5.866h2.143V4.004h1.861V1.861h-1.861V0h-2.143v1.861h-1.862v2.143h1.862z"
                                      />
                                    </g>
                                  </g>
                                </g>
                              </svg>
                            </span>
                          </div>
                          <div className="err-message">
                            {parcticeErrorModel.PracticeName}
                          </div>

                          <div className="form-group has-value select-form-group">
                            <Multiselect
                              className="multi-select"
                              options={option} // Options to display in the dropdown
                              showCheckbox={true}
                              placeholder="Select Dental Specialty"
                              displayValue="name"
                              closeOnSelect={false}
                              onSelect={onSpeSelect}
                              onRemove={onSpeRemove}
                            />
                            <span className="input-icon1">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="30"
                                height="27"
                                viewBox="0 0 30 27"
                              >
                                <g>
                                  <g>
                                    <g>
                                      <path
                                        fill="#f87c56"
                                        d="M29.18 13.09L16.665.79a2.79 2.79 0 0 0-3.892 0L.258 13.09a.862.862 0 1 0 1.209 1.231l12.515-12.3c.406-.4 1.067-.4 1.474 0l12.515 12.3a.86.86 0 0 0 1.22-.01.863.863 0 0 0-.011-1.22z"
                                      />
                                    </g>
                                    <g>
                                      <path
                                        fill="#f87c56"
                                        d="M15.57 15.496V12.72h-1.718v2.776a.863.863 0 0 1-.862.862h-2.776v1.717h2.776c.476 0 .862.386.862.862v2.776h1.718v-2.776c0-.476.385-.862.862-.862h2.775v-1.717h-2.775a.862.862 0 0 1-.862-.862z"
                                      />
                                    </g>
                                    <g>
                                      <path
                                        fill="#f87c56"
                                        d="M20.932 18.937a.862.862 0 0 1-.863.863h-2.775v2.775a.862.862 0 0 1-.862.862H12.99a.862.862 0 0 1-.863-.862V19.8H9.352a.862.862 0 0 1-.863-.863v-3.441c0-.477.386-.863.863-.863h2.775v-2.775c0-.477.386-.862.863-.862h3.442c.476 0 .862.385.862.862v2.775h2.775c.476 0 .863.386.863.863zM14.71 3.715L2.668 15.55c-.073.071-.149.137-.227.198v7.435a3.758 3.758 0 0 0 3.753 3.754h17.049a3.758 3.758 0 0 0 3.754-3.754v-7.422a2.622 2.622 0 0 1-.243-.21z"
                                      />
                                    </g>
                                  </g>
                                </g>
                              </svg>
                            </span>
                            <span className="select-down-arrow">
                              <i
                                className="fa fa-chevron-down"
                                aria-hidden="true"
                              ></i>
                            </span>
                          </div>
                          <div className="err-message">
                            {parcticeErrorModel.Speciality}
                          </div>
                        </div>
                        <div className="account-btn-block">
                          <div className="btn-block">
                            <button
                              type="button"
                              className={`btn btn-outline-primary blue-btn-bg ${
                                loader ? "btn-loading-state" : ""
                              }`}
                              onClick={signUp}
                            >
                              <div className="button-inner">
                                {loader && (
                                  <Spinner
                                    style={{ verticalAlign: "middle" }}
                                  />
                                )}
                              </div>
                              <span className="btn-text">Sign up</span>
                            </button>
                          </div>
                        </div>
                        <div className="forget-link  text-right link-purple signup-link">
                          <p>
                            Already have Account ?
                            <a
                              href="#"
                              className="h5 f-600 pl-2"
                              onClick={redirectToSignin}
                            >
                              Sign in
                            </a>
                          </p>
                        </div>
                        <div className="account-btn-block text-center pt-2">
                          <a
                            onClick={goToStepTwo}
                            className="back-button mr-auto pl-2"
                          >
                            {/* <i className="fa fa-chevron-left" aria-hidden="true"></i> */}
                            <i className="fa fa-chevron-left"></i>
                            Back
                          </a>
                        </div>
                      </form>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </ModalBody>
      </Modal>
      <Home />
      {/* </div>
    </div> */}
    </section>
  );
};
export default SignInPage1;
