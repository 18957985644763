// Declare all application const.
export const API_URL = "https://recordlinc.com/rlapi/api";
// export const API_URL = "https://4c1f453ebf09.ngrok.io/api";
export const RecordLincURL = "https://recordlinc.com/";

export const Title = "Dentistry Flywheels";
export const ThemeColor = "#4460ac";
export const Logo = "https://refer.oneclickreferral.com/Images/Fly_Logo_2.png";
export const LogoWithLabel =
  "https://refer.oneclickreferral.com/Images/Fly_Logo_1.png";
export const ProductName = "Dentistry Flywheels";
export const FavIcon =
  "https://refer.oneclickreferral.com/Images/fly_favicon_32.png";

  export const IntigrationAuthKey = "https://integrate.drdds.com/authKey/";

// Set comman Header value for API calls
export const HEADER = {
  headers: {
    "Content-Type": "application/json",
    Company_Id: localStorage.getItem("CompanyId"),
    access_token: localStorage.getItem("token"),
  },
};

export const HEADERMultipart = {
  headers: {
    access_token: localStorage.getItem("token"),
    "Content-Type": "multipart/form-data",
    Company_Id: localStorage.getItem("CompanyId"),
  },
};
