import axios from "axios";
import { API_URL, HEADER, HEADERMultipart } from "../config/appConstatnts";

class Api {

    // Api fro user profile information.
    static getProfile = (userId) => {
        return axios.get(API_URL + "/Userprofile/Get/" + userId, HEADER);
    }

    // Api for specialitys list.
    static getSpecialities = () => {
        return axios.get(
            API_URL + "/Verident/GetSpecialities?IsSignUP=false",
            HEADER
        )
    }

    // Api for get user speciality list.
    static getSelectedSpecialty = (userId) => {
        return axios.get(API_URL + "/Specialty/Get/" + userId, HEADER);
    }

    // Api for get user addressies.
    static getAddress = (userId) => {
        return axios.get(API_URL + "/Address/Get/" + userId, HEADER);
    }

    static postAddress = (model) => {
        let data = {
            State: model.State.Code,
            Country: "US",
            Id: model.Id,
            Name: model.Name,
            Address1: model.Address1,
            Address2: model.Address2,
            City: model.City,
            ZipCode: model.ZipCode,
            Email: model.Email,
            Phone: model.Phone,
            Fax: model.Fax,
            ContactType: model.ContactType,
            NPINumber: model.NPINumber,
        };
        return axios.post(API_URL + "/Address/Post/", data, HEADER);
    };

    // Api for save User Data.
    static postUserData = (model) => {
        return axios.post(API_URL + "/Userprofile/Post", model, HEADER);
    };

    // Get State
    static getState = (countryId) => {
        return axios.get(API_URL + "/State/Get/" + countryId, HEADER);
    };

    //  post image
    static postProfileImage = (data) => {
        return axios.post(
            API_URL + "/UserProfile/UploadProfileImage",
            data,
            HEADERMultipart
        );
    };



}

export default Api
