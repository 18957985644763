import React, { useEffect, useState } from "react";
import {
  Logo,
  ProductName,
  ThemeColor,
  Title,
  FavIcon,
} from "../src/config/appConstatnts";

import Img from "../src/assets/images/bell.png";
import { Router, Route, Switch, NavLink, Redirect } from "react-router-dom";

import "./App.css";
import "./assets/css/style.css";
import "./assets/css/responsive.css";

// import "./assets/css/style.css";
// import "./assets/css/responsive.css";

import Home from "./Home";
// import MyAccount from "./Myaccount";
import Legal from "./Legal";
import ChangePass from "./models/ChangePass";
import SignInPage from "./auth-pages/SignInPage";
import SignUpPage1 from "./auth-pages/SignUpPage1";
import SignUpPage2 from "./auth-pages/SignUpPage2";
import ChangePassPage from "./auth-pages/ChangePassPage";
import ResetPasswordPage from "./auth-pages/ResetPasswordPage";
import ForgotPasswordPage from "./auth-pages/ForgotPasswordPage";
import PracticeMapPage from "./auth-pages/PracticeMapPage";
import Setting from "./app-pages/Setting";
import ProfilePage from "./app-pages/ProfilePage";
import DashboardMonth from "./app-pages/DashboardMonth";
import PracticeAddress from "./auth-pages/PracticeAddress";
import AccountSetup from "./auth-pages/AccountSetup";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle } from "@fortawesome/free-solid-svg-icons";
import {
  a,
  FormControl,
  Navbar,
  Button,
  Nav,
  NavDropdown,
  DropdownSubmenu,
  NavDropdownMenu,
  Dropdown,
} from "react-bootstrap";
import {
  ButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { ToastContainer, toast } from "react-toastify";

import Api from "./API/login";

import "react-toastify/dist/ReactToastify.min.css";

import history from "./history";

function App() {
  useEffect(() => {
    // favicon.href = "redcircle.png";
    //Retrieve domain name from URL.
    document.title = Title;
    let domain = window.location.hostname;
    document.documentElement.style.setProperty("--theme-color", ThemeColor);
    // Check for CompanyId
    document.getElementById("favicon").href = FavIcon;

    if (
      localStorage.getItem("CompanyId") == undefined ||
      localStorage.getItem("CompanyId") == null
    ) {
      // Retrieve CompanyId and Store in localStorage
      Api.getCompanyId(domain)
        .then((res) => {
          localStorage.setItem("CompanyId", 25);
          window.location.reload();
        })
        .catch((error) => {});
    }
  }, []);

  // Check authentication
  const PrivateRoute = ({ component: Component, ...rest }) => (
    <Route
      {...rest}
      render={(props) =>
        localStorage.getItem("token") != undefined &&
        localStorage.getItem("token").length > 0 ? (
          <Component {...props} />
        ) : (
          <Redirect to="/signin" />
        )
      }
    />
  );

  // Check authentication
  const PublicRoute = ({ component: Component, ...rest }) => (
    <Route
      {...rest}
      render={(props) =>
        localStorage.getItem("token") == undefined ||
        localStorage.getItem("token").length == 0 ? (
          <Component {...props} />
        ) : (
          <Redirect to="/dashboard" />
        )
      }
    />
  );

  return (
    <div className="App">
      <Router history={history}>
        <Switch>
          {/* <Route path="/myaccount" component={MyAccount}  exact/> */}
          <PublicRoute path="/" component={SignInPage} exact />
          <PublicRoute path="/signin" component={SignInPage} exact />
          <PrivateRoute path="/dashboard" component={Home} exact />
          {/* <Route path="/legal" component={Legal} exact /> */}
          <PublicRoute path="/signup" component={SignUpPage1} exact />
          {/* <Route path="/signuppage2" component={SignUpPage2} exact /> */}
          <PublicRoute
            path="/ChangePassword"
            component={ChangePassPage}
            exact
          />
          <PublicRoute
            path="/ResetPassword"
            component={ResetPasswordPage}
            exact
          />
          <PublicRoute
            path="/ForgotPassword"
            component={ForgotPasswordPage}
            exact
          />
          {/* <Route path="/PracticeMapPage" component={PracticeMapPage} exact /> */}
          <PrivateRoute path="/setting" component={Setting} exact />
          <PublicRoute path="/activation" component={AccountSetup} exact />
          <PrivateRoute path="/profile" component={ProfilePage} exact />
          {/* <Route path="/dashboard-month" component={DashboardMonth} exact /> */}
          {/* <Route path="/practiceAddress" component={PracticeAddress} exact /> */}
          <Redirect to="/" />
          {/* <Route path="/map" component={summary_map} exact /> */}
        </Switch>
        {/* Router End */}
      </Router>
      <ToastContainer hideProgressBar />
    </div>
  );
}

export default App;
