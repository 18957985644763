// Regex for Validate Email
export const validEmailRegex = RegExp(
  /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
);

// Regex for Validate Char in String
export const validCharRegex = RegExp(/^[a-zA-Z]+$/);

// Regex for Validate Phone number.
export const validPhoneRegex = RegExp(
  /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/
);

// Regex for Validate Password.
export const validPasswordRegex = RegExp(
  /^.*(?=.{8,})(?=.*[a-z])(?=.*[A-Z])(?=.*[\d])(?=.*[@#!$%^&+=]).*$/
);

// Regex for ZipCode.
export const validZipCodeRegex = RegExp(/\d{5,6}$/);

export const validNumberRegex = RegExp(
  /^\d*\.?((25)|(50)|(5)|(75)|(0)|(00))?$/
);
