import React from 'react';
import {
    Nav, Label, Input, Navbar, Tabs,
    Tab,
} from "react-bootstrap";
import { NavLink } from "react-router-dom";

import App from './App';
import ApexCharts from 'react-apexcharts'
import Chart from "react-apexcharts";
import Edit from "../src/assets/images/edit.png";
import User from "../src/assets/images/profile-logo.png";
import Practice from "../src/assets/images/practice-logo.png";
import Card4 from "../src/assets/images/new-patients.svg";
import select from "../src/assets/images/calendar.png";
import Info from "../src/assets/images/information.svg";
import Edit_Profile from "./models/Edit-profile";
import './assets/css/style.css';
import './assets/css/responsive.css';
import MiniAppNavbar from "../src/common-components/MiniAppNavbar";
function Dashboard_historical() {
    const [
        Edit_ProfileShow,
        SetEdit_Profile,
    ] = React.useState(false);

    return (
        <div>
            <MiniAppNavbar />
            <section id="sub-header"></section>


            <section id="Practice" class="map-container my-account">
                <div class="container-fluid">
                    <div class="row map-sidebar-wrapper">

                        <div class="col-12 map-container-col">
                            <div class="white-card p-0 map-section shadow-sm">
                                <div id="sub-header" class="profile-header">
                                   
                                        <div class="">

                                            <div class=" justify-content-center w-100 legal app-tabs">

                                                {/* <ul class="nav nav-pills">
                                            <li class="nav-item">
                                                <a class="nav-link active" data-toggle="pill" href="#terms">Terms and
                                                    Conditions</a>
                                            </li>

                                            <li class="nav-item">
                                                <a class="nav-link" data-toggle="pill" href="#privacy">Privacy
                                                    Policy</a>
                                            </li>

                                        </ul> */}

                                                <Tabs defaultActiveKey="terms" id="profileDataTabs" ClassName="">
                                                    <Tab eventKey="terms" title="Terms and
                                                    Conditions">
                                                        <ul class="list-unstyled">
                                                            <li>
                                                                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem
                                                                Ipsum has been the industry's standard dummy text ever since the 1500s, when an
                                                                unknown printer took a galley of type and scrambled it to make a type specimen book.
                                                                It has survived not only five centuries, but also the leap into electronic
                                                                typesetting, remaining essentially unchanged. It was popularised in the 1960s with
                                                                the release of Letraset sheets containing Lorem Ipsum passages, and more recently
                                                                with desktop publishing software like Aldus PageMaker including versions of Lorem
                                            Ipsum.</p>
                                                            </li>
                                                            <li>
                                                                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem
                                                                Ipsum has been the industry's standard dummy text ever since the 1500s, when an
                                                                unknown printer took a galley of type and scrambled it to make a type specimen book.
                                                                It has survived not only five centuries, but also the leap into electronic
                                                                typesetting, remaining essentially unchanged. It was popularised in the 1960s with
                                                                the release of Letraset sheets containing Lorem Ipsum passages, and more recently
                                                                with desktop publishing software like Aldus PageMaker including versions of Lorem
                                            Ipsum.</p>
                                                            </li>
                                                            <li>
                                                                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem
                                                                Ipsum has been the industry's standard dummy text ever since the 1500s, when an
                                                                unknown printer took a galley of type and scrambled it to make a type specimen book.
                                            It has survived not only five centuries</p>
                                                            </li>
                                                            <li>
                                                                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem
                                                                Ipsum has been the industry's standard dummy text ever since the 1500s, when an
                                                                unknown printer took a galley of type and scrambled it to make a type specimen book.
                                                                It has survived not only five centuries, but also the leap into electronic
                                                                typesetting, remaining essentially unchanged. It was popularised in the 1960s with
                                                                the release of Letraset sheets containing Lorem Ipsum passages, and more recently
                                                                with desktop publishing software like Aldus PageMaker including versions of Lorem
                                            Ipsum.</p>
                                                            </li>
                                                        </ul>
                                                    </Tab>
                                                    <Tab eventKey="Practice" title="Privacy Policy">
                                                        <ul class="list-unstyled">
                                                            <li>
                                                                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem
                                                                Ipsum has been the industry's standard dummy text ever since the 1500s, when an
                                                                unknown printer took a galley of type and scrambled it to make a type specimen book.
                                                                It has survived not only five centuries, but also the leap into electronic
                                                                typesetting, remaining essentially unchanged. It was popularised in the 1960s with
                                                                the release of Letraset sheets containing Lorem Ipsum passages, and more recently
                                                                with desktop publishing software like Aldus PageMaker including versions of Lorem
                                            Ipsum.</p>
                                                            </li>
                                                            <li>
                                                                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem
                                                                Ipsum has been the industry's standard dummy text ever since the 1500s, when an
                                                                unknown printer took a galley of type and scrambled it to make a type specimen book.
                                                                It has survived not only five centuries, but also the leap into electronic
                                                                typesetting, remaining essentially unchanged. It was popularised in the 1960s with
                                                                the release of Letraset sheets containing Lorem Ipsum passages, and more recently
                                                                with desktop publishing software like Aldus PageMaker including versions of Lorem
                                            Ipsum.</p>
                                                            </li>
                                                            <li>
                                                                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem
                                                                Ipsum has been the industry's standard dummy text ever since the 1500s, when an
                                                                unknown printer took a galley of type and scrambled it to make a type specimen book.
                                                                It has survived not only five centuries, but also the leap into electronic
                                                                typesetting, remaining essentially unchanged. It was popularised in the 1960s with
                                                                the release of Letraset sheets containing Lorem Ipsum passages, and more recently
                                                                with desktop publishing software like Aldus PageMaker including versions of Lorem
                                            Ipsum.</p>
                                                            </li>
                                                            <li>
                                                                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem
                                                                Ipsum has been the industry's standard dummy text ever since the 1500s, when an
                                                                unknown printer took a galley of type and scrambled it to make a type specimen book.
                                            It has survived not only five centuries</p>
                                                            </li>
                                                        </ul>
                                                    </Tab>
                                                </Tabs>
                                            </div>
                                        </div>
                                    
                                </div>

                            </div>
                        </div>
                    </div>
                </div>

            </section>

        </div>

    );
}

export default Dashboard_historical;
