import React, { useState } from "react";
import { useHistory } from "react-router-dom";

import { Spinner } from "reactstrap";

import Icon1 from "../assets/images/icon-2.png";
import Icon2 from "../assets/images/eye.png";
import Icon3 from "../assets/images/logo-white.svg";
import MailIcon from "../assets/images/icon-1.png";
import Home from "../Home";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { validEmailRegex } from "../config/appRegex";

import {
  Logo,
  ProductName,
  ThemeColor,
  Title,
  LogoWithLabel,
} from "../config/appConstatnts";
import Api from "../API/login";

// export default function ForgotPasswordPage(){
const ForgotPasswordPage = (props) => {
  // Email field
  const [email, setEmail] = useState("");
  // Email error
  const [emailError, setEmailError] = useState("");
  // Response message
  const [rMessage, setRMessage] = useState("");
  // Loader
  const [loader, setLoader] = useState(false);

  const [eyeTog1, seteyeTog1] = useState("false");
  const handleToggleeyeTog1 = () => {
    seteyeTog1(!eyeTog1);
  };
  const [eyeTog2, seteyeTog2] = useState("false");
  const handleToggleeyeTog2 = () => {
    seteyeTog2(!eyeTog2);
  };
  const [eyeTog3, seteyeTog3] = useState("false");
  const handleToggleeyeTog3 = () => {
    seteyeTog3(!eyeTog3);
  };

  const { buttonLabel, className } = props;

  const [modal, setModal] = useState(true);

  const toggle = () => setModal(modal);

  // Form element chnage event handler
  const handelChange = (event) => {
    event.persist();

    let { value } = event.target;
    value = value.trim();

    setEmail(value);

    if (value.length == 0) {
      setEmailError("Email is required.");
    } else {
      setEmailError(validEmailRegex.test(value) ? "" : "Enter valid email.");
    }
  };

  // On Form submit
  const handelSubmit = (event) => {
    event.preventDefault();

    setRMessage("");

    // Validate email
    if (email.length == 0) {
      setEmailError("Email is required.");
    } else if (!validEmailRegex.test(email)) {
      setEmailError("Enter valid email.");
    } else {
      setLoader(true);
      Api.forgotPassword(email)
        .then((res) => {
          setLoader(false);
          setRMessage(res.data.Message);
          setTimeout(() => redirectToSignin(), 5000);
        })
        .catch((error) => {
          setLoader(false);
          setEmailError(error.response.data.Message);
        });
    }
  };

  let history = useHistory();
  const redirectToSignup = () => {
    history.push("/signup");
  };
  const redirectToSignin = () => {
    history.push("/signin");
  };
  return (
    <section className="common-section vh-0 login-comman-sec">
      {/* <div className="container-fluid h-100">
      <div className="main-block forgot-password align-items-center justify-content-center h-100 d-flex"> */}
      <Modal
        isOpen={modal}
        toggle={toggle}
        className="modal-dialog-centered sign-up-modal"
      >
        <ModalBody className="main-block signin-block">
          <div className="login sub-block">
            <div className="row justify-content-center">
              <div className="col-lg-7 pr-lg-0 forgot-password-row">
                <div className="d-block">
                  <div className="inner-block text-center">
                    <div className="block-1">
                      <h1>Forgot Password</h1>
                      <p className="mb-2">
                        Please enter your email and we will send you email to
                        reset your password
                      </p>
                    </div>

                    <div className="err-message">{rMessage}</div>
                    <form onSubmit={handelSubmit}>
                      <div className="content">
                        <div className="form-group">
                          <input
                            type="text"
                            className="form-control light-border"
                            id="email"
                            name="email"
                            placeholder="Email Address*"
                            maxLength="100"
                            value={email}
                            onChange={handelChange}
                          />
                          <span className="input-icon1">
                            <img
                              src={MailIcon}
                              alt="views"
                              className="img-fluid"
                            />
                          </span>
                        </div>
                        <div className="err-message">{emailError}</div>
                      </div>
                      <div className="account-btn-block">
                        <div className="btn-block">
                          <button
                            type="submit"
                            className={`btn btn-outline-primary blue-btn-bg ${
                              loader ? "btn-loading-state" : ""
                            }`}
                          >
                            <div className="button-inner">
                              {loader && (
                                <Spinner style={{ verticalAlign: "middle" }} />
                              )}
                              <span className="btn-text">
                                Request Password Reset
                              </span>
                            </div>
                          </button>
                        </div>
                        <div className="forget-link  text-right link-purple signup-link">
                          <p>
                            Already have Account ?
                            <a
                              href="#"
                              className="h5 f-600 pl-2"
                              onClick={redirectToSignin}
                            >
                              Sign in
                            </a>
                          </p>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
              <div className="col-5 bg-green d-flex">
                <div className="justify-content-center align-self-center text-center">
                  <img src={LogoWithLabel} className="img-fluid logo-white" />
                  <h1>Welcome</h1>
                  <p>
                    Increase profits with our proven dental marketing strategy
                    that focuses on patient communications.
                  </p>
                  {/* <div className="btn-block">
                    <button type="button" className="btn btn-outline-primary blue-btn-bg " onClick={redirectToSignup}>sign In</button>
                  </div> */}
                  <div className="btn-block signup-white-btn">
                    <button
                      type="button"
                      className="btn btn-outline-primary blue-btn-bg "
                      onClick={redirectToSignin}
                    >
                      sign in
                    </button>
                  </div>
                  <p>Remember your password?</p>
                </div>
              </div>
            </div>
          </div>
        </ModalBody>
      </Modal>
      <Home />
      {/* </div>
    </div> */}
    </section>
  );
};
export default ForgotPasswordPage;
